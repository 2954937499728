@import 'assets/fonts/Montserrat/index.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 33 93% 54%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 14 93% 54%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 24.6 95% 53.1%;
    --radius: 0.5rem;

    --gray-500: 85 85 85;
    --gray-700: 47 46 49;
    --gray-800: 23 24 26;
    --gray-900: 12 12 14;
    --lime-500: 69 201 37;
    --orange-500: 248 114 73;
    --blue-500: 85 176 254;
    --purple-400: 143 85 254;
  }

  .dark {
    --background: 240 8% 5%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 33 93% 54%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 14 93% 54%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 20.5 90.2% 48.2%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply w-full bg-background text-foreground;
  }
}

@layer components {
  .long-text {
    @apply max-w-full overflow-hidden text-ellipsis whitespace-nowrap;
  }
}

* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

.h-screen-excluding-topbar {
  min-height: calc(100vh - 48px);
}

.max-h-screen-excluding-topbar {
  max-height: calc(100vh - 48px);
}

.position-excluding-topbar {
  top: 48px;
}

.w-screen-excluding-sidebar {
  width: calc(100vw - 350px);
}
