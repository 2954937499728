@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  src: url(./Montserrat-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: url(./Montserrat-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url(./Montserrat-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url(./Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url(./Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url(./Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url(./Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url(./Montserrat-ExtraBold.ttf) format('truetype');
}
